import React from 'react';

import {
  Version,
  isHexColor,
  pluginCodeBlockViewer as pluginCodeBlock,
  pluginHashtagViewer as pluginHashtag,
  pluginIndentViewer as pluginIndent,
  pluginLineSpacingViewer as pluginLineSpacing,
  pluginLinkViewer as pluginLink,
  pluginTextColorViewer as pluginTextColor,
  pluginTextHighlightViewer as pluginTextHighlight,
  TextSelectionToolbar,
  TwitterButton,
  WixRicosViewer,
  type RicosTheme,
} from '@wix/ricos';

import {
  pluginAudioLoadable as pluginAudio,
  pluginLinkButtonLoadable as pluginLinkButton,
  pluginActionButtonLoadable as pluginActionButton,
  pluginCollapsibleListLoadable as pluginCollapsibleList,
  pluginDividerLoadable as pluginDivider,
  pluginFileUploadLoadable as pluginFileUpload,
  pluginGalleryLoadable as pluginGallery,
  pluginGiphyLoadable as pluginGiphy,
  pluginHtmlLoadable as pluginHtml,
  pluginImageLoadable as pluginImage,
  pluginLinkPreviewLoadable as pluginLinkPreview,
  pluginPollLoadable as pluginPoll,
  pluginTableLoadable as pluginTable,
  pluginVerticalEmbedLoadable as pluginVerticalEmbed,
  pluginVideoLoadable as pluginVideo,
} from '@wix/ricos/loadable';

import {
  isExperimentEnabled,
  getIsPostPageBundle,
  getExperiments,
  getCurrentUser,
  resolveId,
  getVideoHost,
  getImageHost,
  type AppSettings,
} from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE,
  EXPERIMENT_RICOS_EMBED_BUTTONS,
} from '@wix/communities-blog-experiments';
// @ts-expect-error
import { RicosViewerWrapper } from '@wix/communities-blog-client-common/dist/esm/components';
import useDeviceType from '@app/external/common/hooks/use-device-type';
import { usePostPageShareProviders } from '@app/external/common/hooks/use-post-page-share-providers';
import { type NormalizedPost } from '@app/external/common/types';
import { useActions, useSelector } from '../../../common/components/runtime-context';
import { getAppSettings } from '../../../common/selectors/app-settings-base-selectors';
import { shouldApplyPostDesignInFeed as shouldApplyPostDesignInFeedSelector } from '../../../common/selectors/full-post-selectors';
import { getLocale } from '../../../common/selectors/locale-selectors';
import { getIframeSandboxDomain } from '../../../common/selectors/rce-props-selectors';
import { getSection } from '../../../common/selectors/section-selectors';
import Wix from '../../../common/services/wix-sdk-polyfill';
import {
  isSeo as getIsSeo,
  getInstance,
  getUrl,
  isEditor,
  isPreview,
} from '../../../common/store/basic-params/basic-params-selectors';
import {
  getInstanceValue,
  getMetaSiteId,
} from '../../../common/store/instance-values/instance-values-selectors';
import { getBaseUrl, getSectionUrl } from '../../../common/store/topology/topology-selectors';

import '@wix/ricos/css/plugin-hashtag-viewer.global.css';
import '@wix/ricos/css/plugin-link-viewer.global.css';
import '@wix/ricos/css/ricos-viewer.global.css';

type Props = {
  post: NormalizedPost | undefined;
  initialRawState: NormalizedPost['content'];
  theme?: RicosTheme;
  parentClass?: string;
  getStyle?: (appSettings: AppSettings) => React.CSSProperties;
};

const RicosViewer: React.FC<Props> = (props) => {
  const isDemoPost = props.post?.isDemo ?? false;
  const postId = resolveId(props.post);
  const videoHost = useSelector(getVideoHost);
  const imageHost = useSelector(getImageHost);
  const actions = useActions();
  const { isDesktop, isMobile } = useDeviceType();
  const { isPluginXEnabled } = usePostPageShareProviders();
  const appSettings = useSelector(getAppSettings);
  const isSeo = useSelector(getIsSeo);
  const isInEditor = useSelector(isEditor);
  const isInPreview = useSelector(isPreview);
  const isPostPageBundle = useSelector(getIsPostPageBundle);
  const locale = useSelector(getLocale);
  const instance = useSelector(getInstance);
  const pageUrl = useSelector(getUrl);
  const baseUrl = useSelector(getBaseUrl);
  const section = useSelector(getSection);
  const sectionUrl = useSelector(getSectionUrl);
  const iframeSandboxDomain = useSelector(getIframeSandboxDomain);
  const currentUser = useSelector(getCurrentUser);
  const isHashtagPageAsFeedPageEnabled = useSelector((state) =>
    isExperimentEnabled(state, EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE),
  );
  const isRicosEmbedButtonsEnabled = useSelector((state) =>
    isExperimentEnabled(state, EXPERIMENT_RICOS_EMBED_BUTTONS),
  );
  const experiments = useSelector(getExperiments);
  const shouldApplyPostDesignInFeed = useSelector(shouldApplyPostDesignInFeedSelector);
  const metasiteId = useSelector(getMetaSiteId);
  const visitorId = useSelector(
    (state) => getInstanceValue(state, 'aid') || getInstanceValue(state, 'uid'),
  );

  const slice = {
    appSettings,
    isSeo,
    isInEditor,
    isInPreview,
    isPostPageBundle,
    locale,
    instance,
    pageUrl,
    baseUrl,
    section,
    sectionUrl,
    iframeSandboxDomain,
    currentUser,
    isHashtagPageAsFeedPageEnabled,
    isRicosEmbedButtonsEnabled,
    experiments,
    shouldApplyPostDesignInFeed,
    metasiteId,
    visitorId,
  };

  const ricosViewerProps = {
    disableTheme: true,
    hashtagNavigate: actions.navigateWithinBlog,
    isDesktop,
    isMobile,
    postId,
    videoHost,
    imageHost,
    isTwitterEnabled: isPluginXEnabled,
    ...props,
    ...slice,
    WixRicosViewer,
    pluginAudio,
    pluginCodeBlock,
    pluginDivider,
    pluginLinkButton,
    pluginGallery,
    pluginGiphy,
    pluginHashtag,
    pluginHtml,
    pluginImage,
    pluginLineSpacing,
    pluginIndent,
    pluginLink,
    pluginLinkPreview,
    pluginTable,
    pluginTextColor,
    pluginTextHighlight,
    pluginVideo,
    pluginFileUpload,
    pluginActionButton,
    pluginVerticalEmbed,
    pluginCollapsibleList,
    pluginPoll,
    content: props.initialRawState,
    rceVersion: Version.currentVersion,
    isHexColor,
    TextSelectionToolbar,
    TwitterButton,
    Wix,
    isDemoPost,
    actions: {
      requestFileDownloadUrl: actions.requestFileDownloadUrlPromisified,
      requestLogin: actions.requestLoginPromisified,
      showMessage: actions.showMessage,
    },
    bi: {
      fileDownloaded: actions.fileDownloaded,
    },
  };

  return <RicosViewerWrapper {...ricosViewerProps} />;
};

export default RicosViewer;
